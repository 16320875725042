import Vue from 'vue';
import VueRouter from 'vue-router';



Vue.use(VueRouter);

const routes = [
    {
        path: '/xmly',
        name: 'xmly',
        component: () => import('./views/third/xmly.vue'),
        meta: {
            keepAlive: true
        }
    },
    {
        path: '/thirdPartyPaySuccess',
        name: 'thirdPartyPaySuccess',
        component: () => import('./views/third/paySuccess.vue')
    },
    {
        path: '/relevance',
        name: 'relevance',
        component: () => import('./views/third/relevance.vue')
    },
    {
        path: '/tikTokCard',
        name: 'tikTokCard',
        component: () => import('./views/third/tikTokCard.vue')
    },
    {
        path: '/tikTokPhysical',
        name: 'tikTokPhysical',
        component: () => import('./views/third/tikTokPhysical.vue')
    },
    {
        path: '/moreExchange',
        name: 'moreExchange',
        component: () => import('./views/third/moreExchange.vue')
    },
    {
        path: '/physicalExchange',
        name: 'physicalExchange',
        component: () => import('./views/third/physicalExchange.vue')
    },
    {
        path: '/crystal',
        name: 'crystal',
        component: () => import('./views/crystal/index.vue')
    },
    {
        path: '/inviteVerifier',
        name: 'inviteVerifier',
        component: () => import('./views/inviteVerifier.vue')
    },

    {
        path: '/scanCode',
        name: 'scanCode',
        component: () => import('./views/scanCode.vue')
    },

    

];

const router = new VueRouter({
    mode: 'history',
    routes
});

export default router;
